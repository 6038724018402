<template>
	<div class="total drag" v-draw id="CfbParameter6">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数06_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				一次风优化控制模型
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context">
			<div class="flex">
				<div class="one">
					<div class="flex">
						<div class="button1">
							<div class="button1_word" @click="toCompon(3)">风量软测量</div>
						</div>
						<div class="button2">
							<div class="button2_word" @click="toIpt(infoList.MCSYCL.SELFL1,'按钮','SELFL1','MCSYCL')">
								{{infoList.MCSYCL.SELFL1 ? "软测量" : "实际测量"}}
							</div>
						</div>
					</div>
					<div class="shadow shadow1">
						<div class="flex">
							<div>
								<div class="flex">
									<div class="column1">G01-K1</div>
									<div class="column2" @click="toIpt(infoList.MCSYCL.FLXS,'G01-K1','FLXS','MCSYCL')">
										{{infoList.MCSYCL.FLXS}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">G01-PZ1</div>
									<div class="column2" @click="toIpt(infoList.MCSYCL.FLCS,'G01-PZ1','FLCS','MCSYCL')">
										{{infoList.MCSYCL.FLCS}}
									</div>
								</div>
							</div>
							<div class="location">
								<div class="flex">
									<div class="column1">G01-K2</div>
									<el-tooltip class="item" effect="dark" :content="infoList.MCSYCL.FLXS2"
										placement="top">
										<div class="column2 textov"
											@click="toIpt(infoList.MCSYCL.FLXS2,'G01-K2','FLXS2','MCSYCL')">
											{{infoList.MCSYCL.FLXS2}}
										</div>
									</el-tooltip>
								</div>
								<div class="flex">
									<div class="column1">G01-PZ2</div>
									<el-tooltip class="item" effect="dark" :content="infoList.MCSYCL.FLCS2"
										placement="top">
										<div class="column2 textov"
											@click="toIpt(infoList.MCSYCL.FLCS2,'G01-PZ2','FLCS2','MCSYCL')">
											{{infoList.MCSYCL.FLCS2}}
										</div>
									</el-tooltip>
								</div>
							</div>
						</div>
					</div>
					<div class="shadow shadow2">
						<div class="words">一次风量信号处理模型</div>
						<div class="flex">
							<div class="flex">
								<div class="column1">G02-TC2</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG.TYCFFOP,'G02-TC2','TYCFFOP','MCSFENG')">
									{{infoList.MCSFENG.TYCFFOP}}
								</div>
							</div>
							<div class="location flex">
								<div class="column1">G02-01</div>
								<el-tooltip class="item" effect="dark" :content="infoList.MCSYCL.JSFL" placement="top">
									<div class="column3 textov" @click="toCompon(2,'JSFL','MCSYCL','JSFLVV1','一次风量')"
										@dblclick="Cclick(infoList.MCSYCL.JSFL,'JSFL','MCSYCL','JSFLVV1')">
										{{infoList.MCSYCL.JSFL}}
									</div>
								</el-tooltip>
							</div>
						</div>
						<div class="flex">
							<div class="column1">G02-LJ</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.YCFLJFL,'G02-LJ','YCFLJFL','MCSFENG')">
								{{infoList.MCSFENG.YCFLJFL}}
							</div>
						</div>
					</div>
					<div class="shadow shadow3">
						<div class="words">煤量一次风模型</div>
						<div class="flex">
							<div class="flex">
								<div class="column1">G03-WLYZ</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG.MLGBYCFYZ,'G03-WLYZ','MLGBYCFYZ','MCSFENG')">
									{{infoList.MCSFENG.MLGBYCFYZ}}
								</div>
							</div>
							<div class="location flex">
								<div class="column1">G03-YCFOT</div>
								<div class="column3" @click="toCompon(2,'GMYCFL','MCSFENG','GMYCFLVV1','给煤改变一次风量')"
									@dblclick="Cclick(infoList.MCSFENGMCSFENGCFL,'GMYCFL','MCSFENG','GMYCFLVV1')">
									{{infoList.MCSFENG.GMYCFL}}
								</div>
							</div>
						</div>
						<div class="flex">
							<div class="column1">G03-YCFMLK</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.K1002,'G03-YCFMLK','K1002','MCSFENG')">
								{{infoList.MCSFENG.K1002}}
							</div>
						</div>
					</div>
					<div class="shadow shadow4">
						<div class="words">一次风床温保护模型</div>
						<div class="flex">
							<div class="flex">
								<div class="column1">G04-K4</div>
								<div class="column2"
									@click="toIpt(infoList.MCSGEIMEI.CWGCYCFK,'G04-K4','CWGCYCFK','MCSGEIMEI')">
									{{infoList.MCSGEIMEI.CWGCYCFK}}
								</div>
							</div>
							<div class="location flex">
								<div class="column1">G04-03</div>
								<div class="column3" @click="toCompon(2,'CWGCYCF','MCSGEIMEI','CWGCYCFVV1','床温调节一次风输出量')"
									@dblclick="Cclick(infoList.MCSGEIMEI.CWGCYCF,'CWGCYCF','MCSGEIMEI','CWGCYCFVV1')">
									{{infoList.MCSGEIMEI.CWGCYCF}}
								</div>
							</div>
						</div>
						<div class="flex">
							<div class="column1">G04-HL</div>
							<div class="column2"
								@click="toIpt(infoList.MCSCWKZ.CWBHYCFH,'G04-HL','CWBHYCFH','MCSCWKZ')">
								{{infoList.MCSCWKZ.CWBHYCFH}}
							</div>
						</div>
						<div class="flex">
							<div class="flex">
								<div class="column1">G04-RL</div>
								<div class="column2"
									@click="toIpt(infoList.MCSCWKZ.TC0001,'G04-RL','TC0001','MCSCWKZ')">
									{{infoList.MCSCWKZ.TC0001}}
								</div>
							</div>
							<div class="button2">
								<div class="button2_word"
									@click="toIpt(infoList.MCSCWKZ.CWMAX_ZBSEL,'按钮','CWMAX_ZBSEL','MCSCWKZ')">
									{{infoList.MCSCWKZ.CWMAX_ZBSEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
					<div class="shadow shadow5">
						<div class="words">快速升降负荷模型</div>
						<div class="flex">
							<div class="flex">
								<div class="column1">G05-MBZL</div>
								<el-tooltip class="item" effect="dark" :content="infoList.MCSFENG.MBFHYCF_SP"
									placement="top">
									<div class="column3 textov"
										@click="toCompon(2,'MBFHYCF_SP','MCSFENG','MBYCF_SPVV1','一次风升降负荷改变量')"
										@dblclick="Cclick(infoList.MCSFENG.MBFHYCF_SP,'MBFHYCF_SP','MCSFENG','MBYCF_SPVV1')">
										{{infoList.MCSFENG.MBFHYCF_SP}}
									</div>
								</el-tooltip>
							</div>
							<div class="location flex">
								<div class="column1">G05-02</div>
								<div class="column3" @click="toCompon(2,'YCF_SJFH','MCSFENG','YCF_SJFHVV1','')"
									@dblclick="Cclick(infoList.MCSFENG.YCF_SJFH,'YCF_SJFH','MCSFENG','YCF_SJFHVV1')">
									{{infoList.MCSFENG.YCF_SJFH}}
								</div>
							</div>
						</div>
						<div class="flex">
							<div class="column1">G05-CXSJ</div>
							<div class="column2"
								@click="toIpt(infoList.MCSGEIMEI.SJFH_T,'G04-RL','SJFH_T','MCSGEIMEI')">
								{{infoList.MCSGEIMEI.SJFH_T}}
							</div>
						</div>
					</div>
				</div>
				<div class="two flex">
					<div class="buttonr float1" :style="{
							background:infoList.MCSRPQ__p__RSF06 && !infoList.MCSRPQ__p__RSF06.SP
							? '#2AFC30'
							: 'red',}" @click="infoList.MCSRPQ__p__RSF06
							? toDetail(3,'RSF06','MCSRPQ__p__RSF06','','床温保护一次风输出'): ''">
						R
					</div>
					<div>
						<div class="button float2">
							<div class="button_word" @click="toIpt(infoList.MCSYCL.SEL61,'按钮','SEL61','MCSYCL')">
								{{infoList.MCSYCL.SEL61 ? "投用" : "切除"}}
							</div>
						</div>
						<div class="button float3">
							<div class="button_word"
								@click="toIpt(infoList.MCSCWKZ.CWMAXYCFSEL,'按钮','CWMAXYCFSEL','MCSCWKZ')">
								{{infoList.MCSCWKZ.CWMAXYCFSEL ? "投用" : "切除"}}
							</div>
						</div>
						<div class="button float4">
							<div class="button_word"
								@click="toIpt(infoList.MCSGEIMEI.SJFHAUTO,'按钮','SJFHAUTO','MCSGEIMEI')">
								{{infoList.MCSGEIMEI.SJFHAUTO ? "投用" : "切除"}}
							</div>
						</div>
					</div>
					<div>
						<div class="shadow shadow1">
							<div class="flex">
								<div class="column1">G07-TC1</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.YCFTC,'G07-TC1','YCFTC','MCSFENG')">
									{{infoList.MCSFENG.YCFTC}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">G07-SP1</div>
								<el-tooltip class="item" effect="dark" :content="infoList.MCSFENG.FGFSP_B1"
									placement="top">
									<div class="column3 textov" @click="toCompon(2,'FGFSP_B1','MCSFENG','FGFSP_B1VV1','风量目标值')"
										@dblclick="Cclick(infoList.MCSFENG.FGFSP_B1,'FGFSP_B1','MCSFENG','FGFSP_B1VV1')">
										{{infoList.MCSFENG.FGFSP_B1}}
									</div>
								</el-tooltip>
							</div>
							<div class="flex">
								<div class="column1">G07-SPH</div>
								<el-tooltip class="item" effect="dark" :content="infoList.MCSFENG.YCFSPH"
									placement="top">
									<div class="column2 textov"
										@click="toIpt(infoList.MCSFENG.YCFSPH,'G07-SPH','YCFSPH','MCSFENG')">
										{{infoList.MCSFENG.YCFSPH}}
									</div>
								</el-tooltip>
							</div>
							<div class="flex">
								<div class="column1">G07-SPL</div>
								<el-tooltip class="item" effect="dark" :content="infoList.MCSFENG.YCFSPL"
									placement="top">
									<div class="column2 textov"
										@click="toIpt(infoList.MCSFENG.YCFSPL,'G07-SPL','YCFSPL','MCSFENG')">
										{{infoList.MCSFENG.YCFSPL}}
									</div>
								</el-tooltip>
							</div>
						</div>
						<div class="shadow shadow2 flex">
							<div class="column1">G07-PV</div>
							<el-tooltip class="item" effect="dark" :content="infoList.MCSRPQ__p__RSF04.PV"
								placement="top">
								<div class="column3 textov" @click="toCompon(2,'PV','MCSRPQ__p__RSF04','RSF04_PVVV1','RSF04测量值')"
									@dblclick="Cclick(infoList.MCSRPQ__p__RSF04.PV,'PV','MCSRPQ__p__RSF04','RSF04_PVVV1')">
									{{infoList.MCSRPQ__p__RSF04.PV}}
								</div>
							</el-tooltip>
						</div>
						<div class="shadow shadow3">
							<div class="words">挡板开度自适应系数</div>
							<div class="left flex">
								<div class="column1_one"></div>
								<div class="column1_two"></div>
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__1__rr__,'挡板开度自适应系数','IN_FD__ll__1__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__1__rr__}}
								</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.AV_FD__ll__1__rr__,'挡板开度自适应系数','AV_FD__ll__1__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.AV_FD__ll__1__rr__}}
								</div>
							</div>
							<div class="left flex">
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__1__rr__,'挡板开度自适应系数','IN_FD__ll__1__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__1__rr__}}
								</div>
								<div class="column1_two">-</div>
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__2__rr__,'挡板开度自适应系数','IN_FD__ll__2__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__2__rr__}}
								</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.AV_FD__ll__2__rr__,'挡板开度自适应系数','AV_FD__ll__2__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.AV_FD__ll__2__rr__}}
								</div>
							</div>
							<div class="left flex">
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__2__rr__,'挡板开度自适应系数','IN_FD__ll__2__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__2__rr__}}
								</div>
								<div class="column1_two">-</div>
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__3__rr__,'挡板开度自适应系数','IN_FD__ll__3__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__3__rr__}}
								</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.AV_FD__ll__3__rr__,'挡板开度自适应系数','AV_FD__ll__3__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.AV_FD__ll__3__rr__}}
								</div>
							</div>
							<div class="left flex">
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__3__rr__,'挡板开度自适应系数','IN_FD__ll__3__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__3__rr__}}
								</div>
								<div class="column1_two">-</div>
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__4__rr__,'挡板开度自适应系数','IN_FD__ll__4__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__4__rr__}}
								</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.AV_FD__ll__4__rr__,'挡板开度自适应系数','AV_FD__ll__4__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.AV_FD__ll__4__rr__}}
								</div>
							</div>
							<div class="left flex">
								<div class="column1_one">></div>
								<div class="column1_two"></div>
								<div class="column1_three"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.IN_FD__ll__4__rr__,'挡板开度自适应系数','IN_FD__ll__4__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.IN_FD__ll__4__rr__}}
								</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG__p__YCFDB.AV_FD__ll__5__rr__,'挡板开度自适应系数','AV_FD__ll__5__rr__','MCSFENG__p__YCFDB')">
									{{infoList.MCSFENG__p__YCFDB.AV_FD__ll__5__rr__}}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="buttonr float5" :style="{
							background:infoList.MCSRPQ__p__RSF04 && !infoList.MCSRPQ__p__RSF04.SP
							? '#2AFC30'
							: 'red',}" @click="infoList.MCSRPQ__p__RSF04
							? toDetail(3,'RSF04','MCSRPQ__p__RSF04','','一次风变频软伺服'): ''">
							R
						</div>
						<div class="buttonr float6" :style="{
							background:infoList.MCSRPQ__p__RSF05 && !infoList.MCSRPQ__p__RSF05.SP
							? '#2AFC30'
							: 'red',}" @click="infoList.MCSRPQ__p__RSF05
							? toDetail(3,'RSF05','MCSRPQ__p__RSF05','','一次风挡板软伺服'): ''">
							R
						</div>
					</div>
					<div class="buttonr float7" :style="{
							background:infoList.MCSRPQ__p__RSF08 && !infoList.MCSRPQ__p__RSF08.SP
							? '#2AFC30'
							: 'red',}" @click="infoList.MCSRPQ__p__RSF08
							? toDetail(3,'RSF08','MCSRPQ__p__RSF08','','一次风机电流纠偏'): ''">
						R
					</div>
					<div>
						<div class="PZ1">PZ1</div>
						<div class="PZ2">PZ2</div>
						<div class="button1">
							<div class="button1_word" @click="toIpt(infoList.MCSRPQ.RSF08SEL,'按钮','RSF08SEL','MCSRPQ')">
								{{infoList.MCSRPQ.RSF08SEL ? "电流纠偏投用" : "电流纠偏切除"}}
							</div>
						</div>
						<div class="shadow shadow4 flex">
							<div class="column1">F03-DLTC</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.YCF_LB,'F03-DLTC','YCF_LB','MCSFENG')">
								{{infoList.MCSFENG.YCF_LB}}
							</div>
						</div>
						<div class="PZ3">PZ3</div>
						<div class="PZ4">PZ4</div>
					</div>
				</div>
				<div class="three">
					<div class="shadow shadow1 float1 flex">
						<div class="column1">G08-A01</div>
						<div class="column3" @click="toCompon(2,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1','二次风机变频反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP_B,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1')">
							{{infoList.MCSSOURCE.KF_ECFBP_B}}
						</div>
					</div>
					<div class="shadow shadow1 float2 flex">
						<div class="column1">G08-A02</div>
						<div class="column3" @click="toCompon(2,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1','2#二次风变频反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP2_B,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1')">
							{{infoList.MCSSOURCE.KF_ECFBP2_B}}
						</div>
					</div>
					<div class="buttonx float3" :style="{
							background:
							infoList.MCSMAN__p__MAN15.RM == 1 || infoList.MCSMAN__p__MAN16.RM == 1 || infoList.MCSMAN__p__MAN25.RM == 1 || infoList.MCSMAN__p__MAN27.RM == 1 ? '#2AFC30' : 'red'
							}" @click="toCompon(0,'CFB_MANYCF',4)">A</div>
					<div class="shadow shadow1 float4 flex">
						<div class="column1">G09-A01</div>
						<div class="column3" @click="toCompon(2,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1','二次风机挡板反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB_B,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1')">
							{{infoList.MCSSOURCE.KF_ECFDB_B}}
						</div>
					</div>
					<div class="shadow shadow1 float5 flex">
						<div class="column1">G09-A02</div>
						<div class="column3" @click="toCompon(2,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1','2#二次风挡板反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB2_B,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1')">
							{{infoList.MCSSOURCE.KF_ECFDB2_B}}
						</div>
					</div>
				</div>
			</div>
			<div class="red">
				<div>煤量一次风系数必须设置，否则影响快速升降负荷一次风改变量</div>
			</div>
		</div>



		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
		<AirSoft v-if="AirSoft" @sendStatus="isClose" :infoList="infoList"></AirSoft>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import manyManual from "@/components/manyManual.vue"; //手操器组件
	import AirSoft from "@/views/CfbBoiler/CfbAirSoft/index.vue";
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			manyManual,
			AirSoft
		},
		data: () => {
			return {
				chName: '',
				AirSoft: false,
				manyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter06', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
					case 'AirSoft':
						return this.AirSoft = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3,name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 3:
							return this.AirSoft = true
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter6 {
		width: 83vw;
		height: 91vh;
		background-image: url("~@/assets/images/CfbBoiler/para071_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -45.5vh 0 0 -40.6vw;
		z-index: 999;

		.title-hang {
			width: 83vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 16.8vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 29vw;
			margin-top: 2vh;
		}

		.main {
			width: 83vw;
			height: 3vh;
			margin-top: 4vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 80vw;
			height: 71vh;
			margin-top: 7vh;
			margin-left: 5vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.2vh;
				line-height: 2.3vh;

				.words {
					font-size: 1.39vh;
					line-height: 1.85vh;
					color: #2fc3e3;
					margin-left: 0.5vw;
				}

				.column1 {
					width: 4.5vw;
					color: #8aeaff;
					margin-left: 0.5vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.one {
				.button1 {
					width: 5vw;
					height: 2vh;
					background-color: #22fff7;
					text-align: center;
					margin-left: 0vw;

					.button1_word {
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
					}
				}

				.button2 {
					width: 3.75vw;
					height: 2vh;
					background-color: #22fff7;
					text-align: center;
					margin-left: 6.8vw;

					.button2_word {
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
					}
				}

				.shadow1 {
					width: 15.63vw;
					height: 5vh;
					margin-top: 0.7vh;

					.location {
						margin-left: 1.4vw;
					}
				}

				.shadow2 {
					width: 15.63vw;
					height: 6.48vh;
					margin-top: 0.7vh;

					.location {
						margin-left: 1.4vw;
					}
				}

				.shadow3 {
					width: 15.63vw;
					height: 6.48vh;
					margin-top: 4.4vh;

					.location {
						margin-left: 1.4vw;
					}
				}

				.shadow4 {
					width: 15.63vw;
					height: 8.33vh;
					margin-top: 0.7vh;

					.location {
						margin-left: 1.4vw;
					}

					.button2 {
						width: 3.75vw;
						height: 2vh;
						background-color: #22fff7;
						text-align: center;
						margin-left: 4vw;
						margin-top: -1vh;

						.button2_word {
							font-size: 1vh;
							line-height: 2vh;
							letter-spacing: 0vh;
							color: #0a4c62;
						}
					}
				}

				.shadow5 {
					width: 15.63vw;
					height: 6.48vh;
					margin-top: 0.7vh;

					.location {
						margin-left: 1.4vw;
					}
				}
			}

			.two {
				width: 49.27vw;
				height: 40.74vh;
				background-image: url("~@/assets/images/CfbBoiler/para061.png");
				background-size: 100% 100%;
				padding: 0.1vw;
				margin-top: 4.6vh;

				.float1 {
					margin-top: 27.96vh;
					margin-left: 0.94vw;
				}

				.button {
					width: 3.13vw;
					height: 1.85vh;
					background-color: #22fff7;
					border-radius: 1px;
					text-align: center;

					.button_word {
						font-size: 1.2vh;
						line-height: 1.9vh;
						color: #0a4c62;
					}
				}

				.float2 {
					margin-top: 15.3vh;
					margin-left: 1.98vw;
				}

				.float3 {
					margin-top: 5.8vh;
					margin-left: 1.98vw;
				}

				.float4 {
					margin-top: 7.1vh;
					margin-left: 1.98vw;
				}

				.shadow1 {
					width: 7.29vw;
					height: 9vh;
					margin-top: 2.78vh;
					margin-left: 12.7vw;
				}

				.shadow2 {
					width: 7.29vw;
					height: 2.22vh;
					margin-top: 6.4vh;
					margin-left: 12.7vw;
				}

				.shadow3 {
					width: 8.33vw;
					height: 13vh;
					margin-top: 4.26vh;
					margin-left: 12.03vw;

					.left {
						margin-left: 1vw;
					}

					.column1_one {
						width: 2vw;
						color: #8aeaff;
						text-align: center;
					}

					.column1_two {
						width: 0.42vw;
						color: #8aeaff;
						text-align: center;
					}

					.column1_three {
						width: 2vw;
						color: #01ffba;
						text-align: center;
					}
				}

				.float5 {
					margin-left: 6.32vw;
					margin-top: 18.43vh;
				}

				.float6 {
					margin-left: 6.32vw;
					margin-top: 3.02vh;
				}

				.float7 {
					margin-left: 3.6vw;
					margin-top: 18.43vh;
				}

				.PZ1 {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;
					margin-top: 0.4vh;
				}

				.PZ2 {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;
					margin-top: 5vh;
				}

				.PZ3 {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;
					margin-top: 5.44vh;
				}

				.PZ4 {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;
					margin-top: 5.3vh;
				}

				.button1 {
					width: 7.29vw;
					height: 2.22vh;
					background-color: #22fff7;
					border-radius: 1px;
					text-align: center;
					margin-top: 7vh;
					margin-left: 0.5vw;

					.button1_word {
						font-size: 1.39vh;
						line-height: 2.59vh;
						color: #0a4c62;
					}
				}

				.shadow4 {
					width: 7.29vw;
					height: 2.22vh;
					margin-top: 1.11vh;
					margin-left: 0.5vw;
				}
			}

			.three {
				margin-left: 0vw;

				.shadow1 {
					width: 8.13vw;
					height: 2.22vh;
				}

				.float1 {
					margin-top: 5.33vh;
				}

				.float2 {
					margin-top: 5.82vh;
				}

				.float3 {
					margin-left: 3.02vw;
					margin-top: 8.52vh;
				}

				.float4 {
					margin-top: 7.36vh;
				}

				.float5 {
					margin-top: 5.93vh;
				}

			}

			.red {
				color: red;
				font-size: 1vw;
				line-height: 2vh;
			}
		}
	}
</style>
